import React from "react";
import ReactDOM from "react-dom";
import SystemJS from "systemjs/dist/system-production";
import Headers from "fetch-headers/headers-es5.min";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { withClientState } from "apollo-link-state";
import { RestLink } from "apollo-link-rest";
import gql from "graphql-tag";
import { hydrate } from "emotion";

import { typeDefs, fragmentMatcher } from "./common";
import { errorHandler } from "./common/errorHandler";

// @todo hack проблема при инициализации модулей, с cdn по умолчанию SystemJS добавляет define,
// и после этого инициализация не происходит, обнуляем define
window.define = null;
window.Headers = window.Headers || Headers; // polyfill fetch Headers for old browsers

const HOST_API = process.env.GRAPHQL_ENDPOINT_URL || "/graphql";
const HOST_API_APOLLO = "/graphql-new";
const STYLES_NAMESPACE = "__ids";

async function importComponent(name) {
    return SystemJS.import(name);
}

const IndexApp = async (cachePrefix, gqlCtxToken, srcLink, componentName) => {
    const cache = new InMemoryCache({ fragmentMatcher }).restore(
        window.__APOLLO_STATE__[cachePrefix] || {}
    );

    let clientState = {
        cache,
        typeDefs
    };

    const stateLink = withClientState({
        ...clientState,
        resolvers: {
            Mutation: {
                updateCartData: (_, { count }, { cache }) => {
                    const data = {
                        cartData: {
                            __typename: "CartData",
                            count: count
                        }
                    };
                    const query = gql`
                        query getCartData {
                            cartData @client {
                                count
                            }
                        }
                    `;
                    cache.writeQuery({ query, data });
                    return null;
                }
            }
        }
    });

    const AuthLink = (operation, forward) => {
        operation.setContext(context => ({
            ...context,
            headers: {
                ...context.headers,
                "X-GQL-CTX-TOKEN": gqlCtxToken
            }
        }));

        return forward(operation);
    };

    const restLink = new RestLink({
        endpoints: {
            api: "/api",
            fragment: "/api/v1/fragment"
        },
        credentials: "same-origin"
    });

    let apolloClientConfig = {
        link: ApolloLink.from([
            AuthLink,
            stateLink,
            restLink,
            new HttpLink({
                uri: function(operation) {
                    const { apolloStitchingServer } = operation.getContext();
                    return apolloStitchingServer ? HOST_API_APOLLO : HOST_API;
                },
                credentials: "same-origin"
            })
        ]),
        cache,
        connectToDevTools: false,
        defaultOptions: {
            watchQuery: {
                errorPolicy: "ignore"
            },
            query: {
                errorPolicy: "ignore"
            }
        }
    };

    try {
        const apolloClient = new ApolloClient(apolloClientConfig);

        const GET_RENDER_INFO_QUERY = gql`
            query getRenderInfo {
                renderInfo @client {
                    reactRootId
                    type
                    mainEntity {
                        page
                        blockId
                        id
                        mode
                        metadata {
                            use
                            orderId
                            token
                            grid
                            isVisible
                            titleIsVisible
                            editor
                        }
                    }
                    locale
                    currencyCodeISO
                    pageEntity {
                        id
                    }
                    fragmentEntity {
                        id
                    }
                }
            }
        `;

        const getRenderInfo = apolloClient.query({
            query: GET_RENDER_INFO_QUERY
        });

        const { data } = await getRenderInfo;
        const {
            renderInfo: {
                reactRootId,
                mainEntity,
                locale,
                currencyCodeISO,
                pageEntity,
                fragmentEntity
            }
        } = data;

        let Comp;
        if (srcLink) {
            const res = await importComponent(srcLink);
            Comp = data.renderInfo.type.toLowerCase().endsWith("master")
                ? res.default
                : errorHandler(res.default);
        }
        apolloClient.link = hydrate(
            window[`${STYLES_NAMESPACE}-${reactRootId}`]
        );

        ReactDOM.hydrate(
            <Comp
                fragmentEntity={fragmentEntity}
                {...{
                    apolloClient,
                    mainEntity,
                    locale,
                    currencyCodeISO,
                    pageEntity
                }}
            />,
            document.getElementById(reactRootId)
        );
    } catch (error) {
        console.error("failed to render component", srcLink, error);
        throw error;
    }
};

export { IndexApp };
