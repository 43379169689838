import { renderToStaticMarkup } from "react-dom/server";
import React from "react";

const errorHandler = Component => {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    render() {
      try {
        renderToStaticMarkup(<Component {...this.props} />);
        return <Component {...this.props} />;
      } catch (e) {
        const error =
          `<p>Some problems in ${this.props.mainEntity.blockId} block</p></br>` +
          e.stack
            .toString()
            .split("\n")
            .map(e => `<p>${e}</p>`)
            .join("");

        return (
          <div
            style={{
              padding: "40px",
              textAlign: "center",
              backgroundColor: "red",
              color: "white",
              fontSize: "150%"
            }}
            onClick={this.errorChange}
            dangerouslySetInnerHTML={{ __html: error }}
          ></div>
        );
      }
    }
  };
};

export { errorHandler };
